import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import ReactMarkdown from 'react-markdown/with-html'

import { CodeBlock } from './CodeBlock'

export const RichTextContainer = styled.div`
  ${tw`tracking-wide`}
  ${tw`leading-7`}

  h1, h2, h3, h4, h5, h6 {
    ${tw`font-sans`}
    ${tw`font-semibold`}
    ${tw`text-gray-900`}
    ${tw`mt-8`}
    ${tw`mb-4`}
  }

  ${tw`font-serif`}

  h2 {
    ${tw`text-3xl`}
    ${tw`leading-8`}
  }

  h3 {
    ${tw`text-xl`}
  }

  h4 {
    ${tw`text-lg`}
  }

  a {
    ${tw`text-indigo-600`}

    &:hover {
      ${tw`text-orange-700`}
    }
  }

  p {
    ${tw`my-5`}
  }

  ol {
    ${tw`pl-2`}
  }

  ul {
    li {
      ${tw`relative`}
      ${tw`pl-5`}

      &:before {
        content: '';
        ${tw`absolute`}
        top: 0.56rem;
        left: 0;
        ${tw`border-2`}
        ${tw`border-l-0`}
        ${tw`border-gray-800`}
        ${tw`rounded-r-full`}
        ${tw`w-2`}
        ${tw`h-2`}
      }
    }
  }

  .code-block {
    ${tw`my-5`}
    ${tw`rounded-md`}
  }
`

type RichTextContainerProps = {
  className?: string
  content: string
}

export const RichText: React.FC<RichTextContainerProps> = ({
  className = '',
  content
}) => {
  return (
    <RichTextContainer
      className={className}
    >
      <ReactMarkdown
        source={content}
        renderers={{
          code: CodeBlock
        }}
      />
    </RichTextContainer>
  )
}
