import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { baseStyle } from '../styles/baseStyle'

import { Header, HeaderWrapper } from './Header'
import { Footer, FooterWrapper } from './Footer'

const LayoutWrapper = styled.div`
  ${baseStyle}
  ${tw`flex`}
  ${tw`flex-col`}
  ${tw`min-h-screen`}

  ${HeaderWrapper} {
    ${tw`flex-none`}
  }

  main {
    flex: 1 0 auto;
  }

  ${FooterWrapper} {
    ${tw`flex-none`}
  }
`

type LayoutProps = {
  className?: string
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  className = ''
}) => {
  return (
    <LayoutWrapper className={className}>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </LayoutWrapper>
  )
}