import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const ContainerWrapper = styled.div`
  ${tw`container`}
  ${tw`mx-auto`}
  ${tw`px-6`}
`

type ContainerProps = {
  className?: string
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className = ''
}) => {
  return (
    <ContainerWrapper className={className}>
      {children}
    </ContainerWrapper>
  )
}