import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type SEOProps = {
  title: string
  description?: string,
  lang?: string,
}

export const SEO: React.FC<SEOProps> = ({
  title,
  description,
  lang = 'en'
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang
        }}
      >
        <title>{`${title} | ${site.siteMetadata.title}`}</title>
        <meta name='description' content={metaDescription} />
      </Helmet>
    </div>
  )
}
