import React from 'react'
import Highlight, { Language, defaultProps } from 'prism-react-renderer'
import prismTheme from 'prism-react-renderer/themes/oceanicNext'

type CodeBlockProps = {
  value: string
  language: Language
}

export const CodeBlock: React.FC<CodeBlockProps> = ({
  value,
  language
}) => {
  return (
    // <pre>
    //   <code className={`language-${language}`}>
    //     {value}
    //   </code>
    // </pre>
    <Highlight {...defaultProps} theme={prismTheme} code={value} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={`code-block ${className}`} style={style}>
          <code className={`language-${language}`}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </code>
        </pre>
      )}
    </Highlight>
  )
}
