import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Container } from './Container'

export const FooterWrapper = styled.footer`
  ${tw`pt-8`}
  ${tw`pb-12`}
  ${tw`bg-gray-200`}
`

const StyledContainer = styled(Container)`
  ${tw`flex`}
  ${tw`justify-between`}
`

const Newsletter = styled.div`
  h2 {
    ${tw`text-4xl`}
    ${tw`font-semibold`}
  }

  p {
    ${tw`mb-4`}
  }

  label {
    ${tw`absolute`}
    ${tw`overflow-hidden`}
    width: 1px;
    height: 1px;
    margin: -1px;
  }

  input {
    ${tw`w-full`}
    ${tw`max-w-sm`}
    ${tw`border`}
    ${tw`border-gray-400`}
    ${tw`rounded-lg`}
    ${tw`py-3`}
    ${tw`px-5`}

    &:focus {
      ${tw`outline-none`}
      ${tw`shadow-outline`}
    }
  }

  button {
    ${tw`ml-3`}
    ${tw`rounded-lg`}
    ${tw`py-3`}
    ${tw`px-5`}
    ${tw`bg-gray-600`}
    ${tw`font-semibold`}
    ${tw`text-white`}
  }
`
const About = styled.div`

`

export const Footer: React.FC = () => {
  const [email, setEmail] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [submitStatus, setSubmitStatus] = React.useState('')

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setIsSubmitting(true)
      const response = await addToMailchimp(email)

      const {result, msg} = response

      if (result === 'success') {
        setEmail('')
        setSubmitStatus('success')
      } else if (result === 'error') {
        setSubmitStatus('error')
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <FooterWrapper>
      <StyledContainer>
        <Newsletter>
          <h2>Stay Updated</h2>
          <p>Sign up for our newsletter to stay up to date on the latest articles and news.</p>
          {submitStatus !== 'success' &&
            <form onSubmit={handleSubmit}>
              <label htmlFor='newsletter-email'>Email address</label>
              <input type='email' id='newsletter-email' value={email} onChange={handleEmailChange} name='email' placeholder='Your email address' required />
              <button type='submit' disabled={isSubmitting}>Signup</button>
            </form>
          }
          {submitStatus === 'success' &&
            <div>Thank you for signing up to the newsletter!</div>
          }
        </Newsletter>
        <About>
          StyleCode created by <a href='https://www.jytsang.com' target='_blank'>Jeun Yun Tsang</a>
        </About>
      </StyledContainer>
    </FooterWrapper>
  )
}