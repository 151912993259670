import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'twin.macro'

export const HeaderWrapper = styled.header`
  ${tw`flex`}
  ${tw`flex-auto`}
`

const Logo = styled.div`
  ${tw`mr-4`}
  ${tw`text-4xl`}
  ${tw`font-semibold`}

  a {
    ${tw`block`}
    border-bottom-right-radius: 2rem;
    ${tw`px-8`}
    ${tw`py-4`}
    ${tw`bg-gray-200`}
    ${tw`text-gray-700`}
  }
`

const HeaderNav = styled.nav`
  ${tw`flex`}
  ${tw`font-semibold`}

  ul {
    ${tw`flex`}

    li {
      ${tw`flex`}
    }
  }

  a {
    ${tw`flex`}
    ${tw`items-center`}
    ${tw`px-6`}
    ${tw`py-4`}
    ${tw`text-gray-700`}

    &:hover {
      border-radius: 0 0 1rem 1rem;
      ${tw`bg-gray-600`}
      ${tw`text-white`}

      span {
        ${tw`border-b-4`}
        ${tw`border-dotted`}
      }
    }
  }
`

export const Header: React.FC = () => {

  return (
    <HeaderWrapper>
      <Logo>
        <Link to='/'>
          StyleCode
        </Link>
      </Logo>
      <HeaderNav>
        <ul>
          <li>
            <Link to='/design/'>
              <span>Design</span>
            </Link>
          </li>
          <li>
            <Link to='/development/'>
              <span>Development</span>
            </Link>
          </li>
        </ul>
      </HeaderNav>
    </HeaderWrapper>
  )
}